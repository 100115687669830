import {inject, onMounted, onUnmounted, reactive} from 'vue';
import {useRoute} from 'vue-router'
import { store } from "../store";

export default function Bulk() {
    const axios = inject('axios');


    const bulk = reactive({
        loading: false,
        data: [],
        name:'',
        price:'',
        error: ''
    });

    const getBulk = (val)=>{
        bulk.loading = true
        bulk.name = val.name+"-"+val.opt1;
        bulk.price = val.price;
            axios.authApi.get('/productBulkDiscount/'+val.id).then((resp) => {
                if (resp.status) {
                    bulk.data = resp.data.data;
                } else {
                    bulk.error = resp.data.error;
                }
            }).catch(function (error) {
                console.log(error);
            }).finally(() => (bulk.loading = false));
    };

    const getBulkPrice = (row)=>{
        let data = JSON.parse(localStorage.getItem("lightning_order"));
        let qty = 0;
        data.forEach(t => {
            if(t.bulkTag == row.bulkTag)
                qty += t.qty;
        });

        let bulkTags = store.getters.cart.bulkTags;
        qty = parseInt(qty) + ((bulkTags && bulkTags[row.bulkTag]) ? parseInt(bulkTags[row.bulkTag]) : 0);
        if(row.bulk.length>0){
            const dis = row.bulk.filter(r=> qty >= r.min_quantity)
            const discount_row = dis[dis.length-1]
            let discount_price = discount_row
                ? (
                    row.price -
                    (row.price * discount_row.discount_percentage) / 100
                    ).toFixed(2)
                : Number(row.price).toFixed(2);
            if(discount_price < row.min_price){
                if(row.price > row.min_price){
                discount_price = Math.max(discount_price, row.min_price);
                }
            }
            return Number(discount_price).toFixed(2);
          }
          else{
            return (Number(row.price).toFixed(2))
          }
    };
    const getBulkTotal = (row)=>{
        const getPrc = getBulkPrice(row)
        return (Number(row.qty*getPrc).toFixed(2))
    };
    return {bulk, getBulk, getBulkPrice, getBulkTotal}

}