<template>
  <div class="max-container space-y-12" v-if="!isLoggedIn">
    <GuestBanner
      title="Order quickly and save time with Lightning Order"
      description="Everything from our store available one page."
      button="Join Now"
    />

    <div class="save-time">
      <div>
        <img src="@/assets/images/save-time.png" alt="Save Time" />
      </div>
      <div class="info">
        <h3>Saves time, so you can get back to running your business</h3>
        <p>
          We know you love hanging out with us, but probably have better things
          to do.
        </p>
        <router-link class="link" to="/join-us">
          Join Now
        </router-link>
      </div>
    </div>
    <TestimonialSlider />
  </div>
  <div class="brand w-full pb-10" v-else>
    <div class="collection-banner-container" style="background-color: #342456">
      <Banner
        title="Lightning Order"
        logo="/images/lightning.svg"
        items="The fastest way to add items to your order."
        class="lightning-banner"
      >
        <template #header>
          <ol role="list" class="breadcrumb">
            <li>
              <div class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </div>
            </li>
            <li>
              <div class="breadcrumb-item">
                <span>/&nbsp;</span>
                <a href="#">Lightning Order</a>
              </div>
            </li>
          </ol>
        </template>
      </Banner>
    </div>
    <!-- Listing -->

    <div class="lightning-listing">
      <div class="flex-grow">
        <div class="listing-header">
          <h3>Add products to your order</h3>
          <button
            :class="
              selectedItems.length > 0 ? 'add-action-btn' : 'btn-disabled'
            "
            @click="addProductsIntoCart"
          >
            ADD TO ORDER
          </button>
        </div>
        <div class="flex flex-col">
          <div class="listing-contents">
            <table>
              <thead>
                <tr>
                  <th scope="col">PRODUCT</th>
                  <th scope="col" width="40" class="hidden xl:table-cell"></th>
                  <th scope="col" width="150" class="hidden xl:table-cell">
                    QUANTITY
                  </th>
                  <th scope="col" width="100" class="hidden xl:table-cell">
                    PRICE
                  </th>
                  <th scope="col" width="180" class="hidden xl:table-cell">
                    TOTAL
                  </th>
                </tr>
              </thead>
              <tbody v-for="row in selectedItems" :key="row.id">
                <Disclosure as="template">
                  <tr :id="'item_id_' + row.id">
                    <td class="name">
                      <div class="flex items-center">
                        <div class="item-info">
                          <div class="inner">
                            <img :src="row.imageThumbnailUrl" alt="Image" />
                            <router-link :to="'/products/' + row.slug">
                              <div class="flex-grow">
                                <p>{{ row.name }}</p>

                                <div
                                  class="flex flex-col md:flex-row md:items-center gap-2 pt-1.5"
                                >
                                  <span class="flex-wrap">
                                    {{ row.brand }} -
                                    {{ row.product_type }}
                                  </span>
                                  <!-- <div
                                    v-if="row.outofStock"
                                    :id="row.id"
                                    class="text-gray-300 2xl:hidden"
                                  >
                                    <span class="hidden md:inline-block pr-0.5">&#x2022;</span>
                                    <span class="error">
                                      {{
                                      row.outofStock ?? ""
                                      }}
                                    </span>
                                  </div> -->
                                </div>
                              </div>
                            </router-link>
                          </div>
                          <ul class="on-mobile">
                            <li
                              class="flex items-center justify-between bg-gray-50 rounded px-4 py-2"
                            >
                              <p class="font-medium text-theme-secondary">
                                Price: ${{ commaFormat(getBulkPrice(row)) }}
                              </p>
                              <PlusMinusInput
                                v-model:modelValue="row.qty"
                                :max="row.MaxOrderQty"
                                :min="1"
                                @getQuantity="updateOrderQty($event, row)"
                              />
                            </li>

                            <li
                              class="flex items-center justify-between bg-gray-50 rounded pl-4 pr-6 py-2"
                            >
                              <p class="font-medium text-theme-secondary">
                                Total:
                              </p>
                              <p class="font-medium text-theme-secondary">
                                ${{ commaFormat(getBulkTotal(row)) }}
                              </p>
                            </li>
                            <li
                              class="flex items-center justify-between rounded pl-2 pr-4 py-2"
                            >
                              <div>
                                <DisclosureButton
                                  v-if="row.bulk?.length > 0"
                                  class="bulk-icon flex items-center"
                                >
                                  <Icon
                                    name="package-outline"
                                    class="w-3.5 h-3.5 flex-shrink-0"
                                  />
                                  <span class="ml-2 text-black"
                                    >Bulk Pricing</span
                                  >
                                </DisclosureButton>
                              </div>

                              <button
                                class="flex items-center text-sm text-red-400"
                              >
                                <Icon
                                  class="w-4 h-4"
                                  name="delete"
                                  @click="removeOrder(row)"
                                />Remove
                              </button>
                            </li>
                          </ul>
                        </div>
                        <!-- <span
                          :id="row.id"
                          class="hidden text-red-600 2xl:inline-block"
                      >
                          {{ row.outofStock ?? "" }}
                        </span>-->
                      </div>
                    </td>
                    <td class="hidden xl:table-cell">
                      <DisclosureButton
                        v-if="row.bulk?.length > 0"
                        class="bulk-icon flex items-center"
                        v-tippy="{
                          content:
                            'Click here to see the bulk pricing discounts available for this product.',
                        }"
                      >
                        <Icon name="package-outline" class="w-4 h-4" />
                        <span class="ml-2 text-black">Bulk Pricing</span>
                      </DisclosureButton>
                    </td>
                    <td class="hidden xl:table-cell">
                      <PlusMinusInput
                        v-model:modelValue="row.qty"
                        :max="row.MaxOrderQty"
                        :min="1"
                        @getQuantity="updateOrderQty($event, row)"
                      />
                    </td>
                    <td class="hidden xl:table-cell">
                      <p class="font-medium text-theme-secondary">
                        ${{ commaFormat(getBulkPrice(row)) }}
                      </p>
                    </td>
                    <td class="hidden xl:table-cell">
                      <div class="item-action">
                        <p>${{ commaFormat(getBulkTotal(row)) }}</p>
                        <button>
                          <!-- <Icon name="delete" @click="removeFromCart(cartdata.variantId, cartdata.qty)"/> -->
                          <Icon name="delete" @click="removeOrder(row)" />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <DisclosurePanel
                    as="tr"
                    class="bulk-info-tr"
                    v-slot="{ close }"
                  >
                    <td colspan="5">
                      <div class="bulk-pricing-modal inline-info">
                        <div class="bulk-pricing-header">
                          <Icon name="package" class="pkg-icon" />
                          <div class="text-left">
                            <h3>Bulk Pricing</h3>
                            <p>
                              Automatic volume discounts for {{ row.name }}
                              <strong>${{ row.price }}</strong>
                            </p>
                          </div>
                          <button class="cls-btn" @click="closeBukInfo(close)">
                            <Icon name="close" />
                          </button>
                        </div>
                        <div class="bulk-pricing-content">
                          <table>
                            <thead class="bg-white">
                              <tr>
                                <th scope="col">QUANTITY</th>
                                <th scope="col">DISCOUNT</th>
                                <th scope="col">PRICE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="brow in row.bulk" :key="brow.id">
                                <td>{{ brow.min_quantity }}+</td>
                                <td>
                                  {{ brow.discount_percentage.toFixed(2) }}%
                                </td>
                                <td>
                                  ${{
                                    (
                                      row.price -
                                      (row.price * brow.discount_percentage) /
                                        100
                                    ).toFixed(2)
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </td>
                  </DisclosurePanel>
                </Disclosure>
              </tbody>

              <tbody v-if="selectedItems.length == 0">
                <tr>
                  <td colspan="5">
                    <div class="no-items">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="128"
                        height="128"
                        fill="none"
                        viewBox="0 0 128 128"
                      >
                        <path
                          fill="#B6B6B6"
                          fill-rule="evenodd"
                          d="M89.218 48.208A3 3 0 0191 50.95v26.285a3 3 0 01-1.543 2.622l-24 13.334a3 3 0 01-2.914 0l-24-13.334A3 3 0 0137 77.235V50.95a3 3 0 011.782-2.742l24-10.666a3 3 0 012.436 0l24 10.666z"
                          clip-rule="evenodd"
                          opacity=".3"
                        />
                        <path
                          fill="#000"
                          fill-rule="evenodd"
                          d="M72.901 40.673L50.8 52.952v13.765l6 3V56.482l22.965-12.758-6.864-3.05z"
                          clip-rule="evenodd"
                        />
                        <circle
                          cx="64"
                          cy="64"
                          r="64"
                          fill="#DEDEDE"
                          fill-opacity="0.36"
                        />
                      </svg>
                      <p>There’s nothing in lightning order</p>
                      <span>Search for a product below to add products</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Combobox as="div" class="listing-search" v-model="selectedProduct">
            <ais-instant-search
              :search-client="searchClient"
              index-name="variants_index"
            >
              <ais-search-box class="search-box">
                <template v-slot="{ refine }">
                  <div class="max-w-xl w-full relative">
                    <ComboboxInput
                      @input="refineSearch(refine, $event.currentTarget.value)"
                      @keydown="getSearch($event.currentTarget.value)"
                      @click="
                        $event.currentTarget.value
                          ? getSearch($event.currentTarget.value)
                          : ''
                      "
                      id="searchlightning"
                      name="search-lightning"
                      placeholder="Start typing the name of any product"
                      type="text"
                      autocomplete="off"
                    />
                    <div class="search-icon">
                      <SearchIcon aria-hidden="true" />
                    </div>
                  </div>
                </template>
              </ais-search-box>
              <ais-state-results>
                <template v-slot="{ state: { query } }">
                  <ais-index index-name="variants_index">
                    <ais-hits
                      v-if="query.length > 0 && optionsToggle"
                      class="p-4"
                    >
                      <template v-slot="{ items }">
                        <ComboboxOptions
                          class="listing-search-results"
                          v-click-outside="onClickOutside"
                          static
                        >
                          <div class="items-scroller">
                            <div v-for="val in items" :key="val.objectId">
                              <transition
                                enter-active-class="transition duration-100 ease-out"
                                enter-from-class="transform scale-95 opacity-0"
                                enter-to-class="transform scale-100 opacity-100"
                                leave-active-class="transition duration-75 ease-out"
                                leave-from-class="transform scale-100 opacity-100"
                                leave-to-class="transform scale-95 opacity-0"
                              >
                                <div
                                  v-if="
                                    val.product_active === 1 &&
                                      val.product_status === 'active' &&
                                      val.active === 1 &&
                                      val.status === 'active' &&
                                      (cart.price_list_id == '33' ||
                                        val.tags != 'GGV ONLY')
                                  "
                                >
                                  <ComboboxOption
                                    as="div"
                                    v-slot="{ active }"
                                    :key="val.id"
                                    :value="val"
                                  >
                                    <div
                                      :class="[
                                        val.stockLevelAbsolute == 0 ||
                                        inOrder(val.id) == true ||
                                        isSelected(val)
                                          ? 'out-of-stock'
                                          : '',
                                        active ? 'is-active' : '',
                                      ]"
                                      class="item"
                                      @click.self="onListItemClick(val)"
                                    >
                                      <div class="info">
                                        <img
                                          :src="
                                            val.imageThumbnailUrl != null
                                              ? val.imageThumbnailUrl
                                              : '/images/no-image.png'
                                          "
                                          alt="Image"
                                        />
                                        <div class="flex-grow">
                                          <p>
                                            {{ val.productName }} -
                                            {{ val.label }}
                                          </p>
                                          <span>
                                            <span
                                              v-if="
                                                customPriceList[val.id] ??
                                                  val.price
                                              "
                                            >
                                              ${{
                                                Number(
                                                  customPriceList[val.id] ??
                                                    val.price
                                                ).toFixed(2)
                                              }}
                                              |
                                            </span>
                                            {{ val.brand }} -
                                            {{ val.product_type }}
                                          </span>
                                        </div>
                                      </div>
                                      <span class="flex pt-1 pl-12 lg:p-0">
                                        <div
                                          v-if="val.stockLevelAbsolute == 0"
                                          class="flex"
                                        >
                                          <button
                                            v-if="!notifyProduct[val.id]"
                                            class="red-badge"
                                            @click="Notify(val)"
                                          >
                                            <Icon name="bell-ring" />
                                            <span
                                              v-if="
                                                val.first_landed_date == null
                                              "
                                              >Coming Soon</span
                                            >
                                            <span
                                              v-else-if="
                                                val.stockLevelAbsolute == 0
                                              "
                                              >Out of Stock</span
                                            >
                                          </button>

                                          <button
                                            v-else
                                            class="red-badge notified"
                                            @click="doNotNotify(val)"
                                          >
                                            <Icon name="checkmark" />
                                            <span>We'll notify you</span>
                                          </button>
                                        </div>
                                      </span>
                                      <div
                                        v-if="inOrder(val.id)"
                                        class="added-badge"
                                      >
                                        <Icon name="lightning" />
                                        <span>Already In Order</span>
                                      </div>
                                      <div
                                        v-else-if="isSelected(val)"
                                        class="added-badge"
                                      >
                                        <Icon name="lightning" />
                                        <span>In LIghtning Order</span>
                                      </div>
                                    </div>
                                  </ComboboxOption>
                                </div>
                              </transition>
                            </div>
                            <div
                              v-if="!items.length"
                              class="text-xs uppercase text-brand-body text-opacity-75 tracking-wide py-5 px-4"
                            >
                              No results have been found.
                            </div>
                          </div>
                        </ComboboxOptions>
                      </template>
                    </ais-hits>
                  </ais-index>
                </template>
              </ais-state-results>
            </ais-instant-search>
          </Combobox>
        </div>
      </div>
    </div>

    <Confirm
      :isVisible="showConfirmDialouge"
      cancelButton="Ok"
      confirmButton="stay here"
      @cancel="returnToRoute"
      @confirm="showConfirmDialouge = false"
    >
      <p>
        The products in lightning order haven't been added to your order. They
        will be saved here so you can come back and add them
      </p>
    </Confirm>

    <Confirm
      :width="48"
      :isVisible="showOutOfStockModal"
      title="These items are out of stock"
      cancelButton="Cancel"
      confirmButton="Remove All"
      @cancel="showOutOfStockModal = false"
      @confirm="removeOutOfStock()"
    >
      <div class="out-of-stock header">
        <div class="col-span-10 title">Product</div>
        <div class="col-span-2 title">Quantity</div>
      </div>
      <div
        class="out-of-stock"
        v-for="item in outOfStockProducts"
        :key="item.id"
      >
        <div class="col-span-10 flex items-center gap-4">
          <img :src="item.imageThumbnailUrl" alt="Image" />
          <div>
            <p>{{ item.name }}</p>
            <span>{{ item.brand }} - {{ item.product_type }}</span>
          </div>
        </div>
        <div class="col-span-2 text-sm text-theme-secondary font-medium">
          {{ item.qty }}
        </div>
      </div>
    </Confirm>
  </div>
</template>

<script>
import Mymixin from "@/modules/mixins";
import { ref } from "vue";
import { SearchIcon } from "@heroicons/vue/solid";
import Banner from "../common/Banner.vue";
import { inject } from "vue";
import algoliasearch from "algoliasearch/lite";
import vClickOutside from "click-outside-vue3";
import { useStore } from "vuex";
import Bulk from "@/modules/Bulk";
import Notifications from "@/modules/Notifications";
import TestimonialSlider from "@/components/TestimonialSlider";
import GuestBanner from "@/components/GuestBanner";
import debounce from "lodash/debounce";

import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import Segments from "@/modules/Segments";

export default {
  name: "Lightning",
  mixins: [Mymixin],
  data() {
    return {
      searchClient: algoliasearch(
        process.env.ALGOLIA_APP_ID,
        process.env.ALGOLIA_API_KEY
      ),
      selectedItems: [],
      selectedProduct: null,
      optionsToggle: true,
      customPriceList: "",
      notifyProduct: "",
      showConfirmDialouge: false,
      navigateTo: null,
      showOutOfStockModal: false,
      width: 48,
      outOfStockProducts: [],
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    SearchIcon,
    Banner,
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    TestimonialSlider,
    GuestBanner,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },
  computed: {
    cart() {
      return this.$store.getters.cart;
    },
  },
  created() {
    this.selectedItems = this.getOrder();
  },
  mounted() {
    if (this.selectedItems.length > 0) this.checkOrder();

    this.getCustomPriceList();
    this.getNotifyProduct();
  },
  methods: {
    onListItemClick(val) {
      const isInLightning = this.inOrder(val.id) || this.isSelected(val);
      if (isInLightning) return;
      if (val.stockLevelAbsolute == 0) {
        if (!this.notifyProduct[val.id]) {
          this.Notify(val);
        } else {
          this.doNotNotify(val);
        }
      }
    },
    refineSearch: debounce(function(refine, value) {
      if (value) {
        this.showDropdown = this.optionsToggle = true;
      }
      refine(value);
    }, 400),
    returnToRoute() {
      this.navigateTo.isRoute = true;
      this.$router.push(this.navigateTo);
    },

    removeOutOfStock() {
      this.outOfStockProducts.forEach((item) => {
        //Get Current Item
        let currentItem = document.getElementById("item_id_" + item.id);
        currentItem.remove();

        //Get Items From Local Storage
        let items = JSON.parse(localStorage.getItem("lightning_order"));

        //Filter Items
        let newItems = items.filter((i) => i.id != item.id);

        //Set Items Into Local Storage
        localStorage.setItem("lightning_order", JSON.stringify(newItems));

        //Set Items Into State
        this.selectedItems = newItems;
      });
      this.showOutOfStockModal = false;
    },
    getCustomPriceList() {
      if (this.axios.isLoggedIn) {
        this.axios.authApi.get("/customerPriceList").then((res) => {
          this.customPriceList = res.data.data;
        });
      }
    },
    getNotifyProduct() {
      if (this.axios.isLoggedIn) {
        this.axios.authApi.get("/productStock").then((res) => {
          this.notifyProduct = res.data.data;
        });
      }
    },
    async checkOrder() {
      if (this.axios.isLoggedIn) {
        const ids = this.selectedItems.map((r) => r.id);
        const res = await this.axios.authApi.post("/checkStock", {
          variant_ids: ids,
        });
        const checkStock = res.data.data;
        this.selectedItems.forEach((r) => {
          checkStock.forEach((el) => {
            if (el.id == r.id) {
              r.price = el.price;
              if (el.message) {
                r.outofStock = el.message;
                r.stockLevelAbsolute = 0;
              }
            }
          });
        });
      }
    },
    getOrder() {
      let data = JSON.parse(localStorage.getItem("lightning_order"));
      return data ? data.filter((d) => !!d) : [];
    },
    setOrder(item) {
      localStorage.setItem(
        "lightning_order",
        JSON.stringify(item.filter((d) => !!d))
      );
    },
    async updateOrder(item) {
      let isSelected = this.isSelected(item);
      if (!isSelected) {
        const res = await this.axios.authApi.get(
          "/productBulkDiscount/" + item.id
        );

        // const bulkTags = await this.axios.authApi.get("/getBulkTags");
        // item.bulk = res.data.data;
        // item.brand = item.brand;
        // item.category = item.product_type;
        // item.name = item.productName;
        // item.qty = 1;

        // item.slug = pro.slug;
        // item.product_id = pro.id;

        // if(bulkTags.data.status){
        //   localStorage.setItem("bulk_tags", JSON.stringify(bulkTags.data.data));
        // }
        if (res.data.status !== false) {
          this.selectedItems.push(res.data.data);
          this.setOrder(this.selectedItems);
        }
      }
    },
    updateOrderQty(qty, item) {
      const updatedItems = this.selectedItems.map((el) => {
        if (el.id == item.id) {
          el = item;
          el.qty = qty[0];
        }
        return el;
      });
      this.selectedItems = updatedItems;
      this.setOrder(this.selectedItems);
    },
    removeOrder(item) {
      if (item) {
        this.selectedItems = this.selectedItems.filter((r) => r.id != item.id);
        this.setOrder(this.selectedItems);
      } else {
        this.selectedItems = [];
        localStorage.removeItem("lightning_order");
      }
    },
    isSelected(item) {
      let is = this.selectedItems.filter((r) => r.id == item.id);
      return is.length > 0 ? true : false;
    },
    addProductsIntoCart() {
      let order = this.selectedItems.map((r) => ({
        variant_id: r.id,
        qty: r.qty,
      }));
      if (order.length > 0) {
        this.productIntoCart(order, this.selectedItems);
      } else {
        this.$store.dispatch("setToast", {
          status: true,
          msg: "Add Order First!!!",
          type: "danger",
        });
      }
    },
    inOrder(variant_id) {
      return this.cart.itemsByCat.find((item) =>
        item.items.find((element) => element.variantId == variant_id)
      )
        ? true
        : false;
    },

    onClickOutside() {
      this.showDropdown = this.optionsToggle = false;
    },

    getSearch: function(val) {
      if (val) {
        this.showDropdown = this.optionsToggle = true;
      }
    },
    Notify(v) {
      this.notifyProduct[v.id] = 12;
      let p = {
        id: v.id,
        slug: v.slug,
        brand: v.brand,
        product_type: v.product_type,
        name: v.productName,
      };
      this.notifyme(v, p, "Lightning Order");
    },
    doNotNotify(v) {
      delete this.notifyProduct[v.id];
      let p = {
        id: v.id,
        slug: v.slug,
        brand: v.brand,
        product_type: v.product_type,
        name: v.productName,
      };
      this.deleteNotify(v, p, "Lightning Order");
    },
  },
  watch: {
    selectedProduct(val) {
      if (val.stockLevelAbsolute > 0 && !this.inOrder(val.id))
        this.updateOrder(val);
    },
    selectedItems: function() {
      return this.getOrder();
    },
  },
  beforeRouteLeave(to, from) {
    if (!this.isLoggedIn) {
      return true;
    }
    if (this.navigateTo ? this.navigateTo.isRoute : 0) {
      return true;
    }
    if (this.selectedItems.length > 0) {
      this.showConfirmDialouge = true;
      this.navigateTo = to;
      return false;
    }
  },
  beforeMount() {
    this.$store.dispatch("getCart", { ax: null });
  },
  setup() {
    const axios = inject("axios");
    const store = useStore();
    const { getBulkPrice, getBulkTotal } = Bulk();
    const isNotified = ref(false);
    const showDropdown = ref(false);
    const showBulkPricing = ref(false);
    const { notify, notifyme, deleteNotify } = Notifications();
    const isLoggedIn = axios.isLoggedIn;

    const { ProductsAdded } = Segments();

    function productIntoCart(items, selectedItems) {
      axios.authApi
        .post("/addProductsToCart", { items: items })
        .then((resp) => {
          if (resp.data.status) {
            ProductsAdded(selectedItems, resp.data.data);
            store.dispatch("UpdateBlukIntoCart", resp.data.data);
            this.removeOrder();
          } else {
            this.outOfStockProducts = this.selectedItems.filter((item) => {
              return resp?.data?.data.find((i) => i.variant_id == item.id);
            });
            this.showOutOfStockModal = true;
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => 0);
    }

    const bulk = ref();

    function ShowBluk(val) {
      bulk.value = val;
      console.log(val);
      showBulkPricing.value = true;
    }

    // function isBulkPrice(row){
    //   if(row.bulk.length>0){
    //     const dis = row.bulk.filter(r=> row.qty > r.min_quantity)
    //     const discount_row = dis[dis.length-1]
    //     return discount_row?(row.price-(row.price*discount_row.discount_percentage)/100).toFixed(2): (Number(row.price).toFixed(2))
    //   }
    //   else{
    //     return (Number(row.price).toFixed(2))
    //   }

    //   // (bulk.price - (bulk.price*row.discount_percentage)/100).toFixed(2)
    // }

    const closeBukInfo = (close) => close();

    return {
      getBulkPrice,
      getBulkTotal,
      productIntoCart,
      bulk,
      showDropdown,
      axios,
      showBulkPricing,
      isNotified,
      ShowBluk,
      notify,
      notifyme,
      deleteNotify,
      isLoggedIn,
      closeBukInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.collection-banner-container {
  background-image: url("/images/lines-pattern.png");

  &.bg-full-image {
    @apply bg-cover;
  }
}

.lightning-listing {
  @apply py-10 space-x-10 flex items-start pb-24;
  @include maxContainer();

  .listing-header {
    @apply flex flex-col lg:flex-row lg:items-center justify-between mb-8 gap-6;

    h3 {
      @apply text-2xl text-brand-primary font-semibold;
    }

    .add-action-btn {
      @apply w-48 bg-brand-primary text-white uppercase font-normal tracking-wide text-xs py-2.5 px-3 rounded;
    }

    .btn-disabled {
      @apply w-48 bg-gray-500 cursor-auto pointer-events-none text-white uppercase font-normal tracking-wide text-xs py-2.5 px-3 rounded;
    }
  }

  .listing-contents {
    @apply bg-white rounded-lg;

    table {
      @apply min-w-full divide-y divide-gray-100;

      thead {
        @apply bg-white;

        th {
          @apply px-6 py-4 text-left text-sm font-medium text-brand-body uppercase tracking-wider;
        }
      }

      tbody {
        @apply bg-white divide-y divide-gray-100;

        td {
          @apply px-6 py-4 whitespace-nowrap text-sm text-theme-body;
          &.name {
            @apply font-medium text-gray-900 group;
          }

          .bulk-icon {
            @apply text-theme-secondary flex items-center border-b border-dashed border-theme-secondary;
          }

          .item-info {
            @apply flex-grow;
            .inner {
              @apply flex items-center space-x-4 flex-shrink-0 flex-grow;
              img {
                @apply w-5 h-5 md:w-10 md:h-10 object-contain object-center;
              }

              p {
                @apply text-sm leading-none text-theme-secondary font-medium;
              }

              span {
                @apply text-sm leading-none text-theme-body font-normal;

                &.error {
                  @apply text-red-600 text-xs;
                }
              }
            }

            .on-mobile {
              @apply flex-grow xl:hidden flex-col space-y-2 pl-6 py-5;

              .bulk-icon {
                span {
                  @apply text-sm;
                }
              }
            }
          }

          .item-action {
            @apply flex items-center space-x-5 flex-shrink-0 flex-grow justify-between;
            p {
              @apply font-medium text-theme-secondary;
            }

            button {
              @apply transition cursor-pointer bg-transparent focus:outline-none;
              svg {
                @apply w-5 h-5;
              }
            }
          }
        }
      }
    }
  }

  .inline-info {
    @apply w-auto -mx-6 md:w-full md:mx-0;
    max-width: unset;

    .pkg-icon {
      @apply hidden md:inline-block;
    }
  }

  .bulk-pricing-content {
    table {
      thead {
        @apply bg-white;

        th {
          @apply px-3 py-3 md:py-3.5 md:px-4 text-sm md:text-md;
        }
      }

      tbody {
        td {
          @apply px-3 py-3 md:py-3.5 md:px-4 text-sm md:text-md;
        }
      }
    }
  }

  .no-items {
    @apply bg-white flex w-full items-center justify-center flex-col py-12 px-4 space-y-2;

    p {
      @apply text-base text-brand-secondary font-semibold;
    }

    span {
      @apply text-sm text-brand-body text-center;
    }
  }

  .listing-search {
    // @apply w-full relative;

    .search-box {
      @apply relative flex items-center w-full  p-4 bg-white;

      input {
        @apply shadow-sm text-brand-body appearance-none block text-xs font-normal w-full pl-5 pr-3 py-3.5 border border-none rounded leading-5 bg-brand-body bg-opacity-5 placeholder-gray-400 focus:outline-none sm:text-sm focus:ring-2 focus:ring-brand-indigodark;
      }

      .search-icon {
        @apply absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none;

        svg {
          @apply h-5 w-5 text-brand-body;
        }
      }
    }
  }

  .listing-search-results {
    @apply w-full max-w-3xl  bg-white rounded shadow-nav border border-gray-100 top-full relative -mt-5;
    .items-scroller {
      @apply overflow-y-auto;
      max-height: 50vh;
      @include CssScrollbar();
    }

    .item {
      @apply flex flex-col items-start lg:flex-row lg:items-center justify-between py-3 px-4 cursor-pointer hover:bg-indigo-50 list-none;

      &.is-active {
        @apply bg-indigo-50;
      }

      &.out-of-stock {
        @apply cursor-default hover:bg-indigo-50 hover:bg-opacity-50;

        * {
          @apply pointer-events-none;
        }

        button {
          @apply pointer-events-auto;
        }

        .info {
          @apply opacity-80;
        }

        .red-badge {
          @apply opacity-100;
        }
      }

      .info {
        @apply flex items-center space-x-4 flex-grow;

        img {
          @apply w-10 h-10 bg-white rounded object-contain object-center flex-shrink-0;
        }

        p {
          @apply text-sm leading-none text-brand-secondary font-medium;
        }

        span {
          @apply text-xs leading-none text-brand-body font-normal;
        }
      }

      .red-badge {
        @apply flex-shrink-0 leading-none text-xxs font-normal text-brand-red py-1 px-2 rounded-full inline-flex items-center justify-center bg-brand-red bg-opacity-5 space-x-1;
        &.notified {
          @apply bg-theme-secondary text-white font-light;
          span {
            @apply text-white;
          }
        }

        &:focus {
          @apply outline-none ring-0 ring-transparent;
        }

        svg {
          @apply w-3.5 h-3.5;
        }

        span {
          @apply text-brand-red;
        }
      }

      .added-badge {
        @apply flex-shrink-0 leading-none text-xxs font-normal text-brand-primary py-1 px-2 rounded-full inline-flex items-center justify-center bg-gray-50 space-x-1;
        svg {
          @apply w-3.5 h-3.5;
        }

        span {
          @apply text-brand-body;
        }
      }
    }
  }
}

.notify-bell {
  @apply flex items-center space-x-3 uppercase text-xs;
  .n-icon {
    @apply inline-flex items-center justify-center w-10 h-10 bg-brand-red text-white rounded;

    svg {
      @apply w-5 h-5;
    }
  }

  &.undo {
    .n-icon {
      @apply bg-theme-secondary;
    }
  }
}

.save-time {
  @apply flex flex-col-reverse items-center xl:grid xl:grid-cols-2 px-6 py-8 xl:p-16 bg-white rounded-lg;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);

  img {
    @apply max-w-full px-5 py-8 xl:py-0;
  }

  .info {
    @apply flex flex-col items-start justify-center space-y-4 text-left h-full px-5;

    h3 {
      @apply text-3xl font-semibold text-theme-secondary leading-normal;
    }

    p {
      @apply text-base font-light text-theme-body;
    }

    button,
    .link {
      @apply bg-transparent border-none focus:outline-none text-base font-medium hover:underline text-theme-primary;
    }
  }
}

.out-of-stock {
  @apply grid grid-cols-12 gap-4;

  &.header {
    @apply border-b pb-3 border-gray-100;

    &.title {
      @apply text-left text-sm font-medium text-brand-body uppercase tracking-wider;
    }
  }

  p {
    @apply text-sm leading-none text-theme-secondary font-medium;
  }

  img {
    @apply w-5 h-5 md:w-10 md:h-10 object-contain object-center;
  }

  span {
    @apply text-sm leading-none text-theme-body font-normal;
  }
}
</style>
